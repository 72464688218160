import authPlugin from '@daangn/plantae-plugin-auth';

import type { KarrotBridge } from '../bridge';

const plantaeAuthPlugin = (karrotBridge: KarrotBridge, isApp: boolean) => {
  return authPlugin({
    bridge: karrotBridge,
    options: {
      fallbackAuthToken: isApp || {
        value: import.meta.env.VITE_AUTH_TOKEN ?? undefined, //local 환경 테스트
      },
    },
  });
};

export default plantaeAuthPlugin;
