import type { BizAccountInfo } from '@daangn/business-sdk-webview';
import { Avatar } from '@daangn/sprout-components-avatar';
import { BoxButton } from '@daangn/sprout-components-button';
import { vars } from '@seed-design/design-token';
import { IconChevronRightFill, IconWarningFill } from '@seed-design/icon';
import { useActivityParams } from '@stackflow/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { _isApp, TargetType } from '../../constants';
import { useAuthContext } from '../../context';
import type { TypeActivityParams } from '../../stackflow';
import { useFlow } from '../../stackflow';
import { typography } from '../../styles/f.css';
import { useRedeemQR } from '../../utils/useRedeemQR';
import {
  boxButtonWrapperStyle,
  containerRootStyle,
  disclaimerStyle,
  disclaimerTextStyle,
  profileDetailInfoContainerStyle,
  profileRegionStyle,
  warningIconFillStyle,
  wrapperStyle,
} from './BizProfileSelectedList.css';

interface BizProfileSelectedListProps {
  businessProfile: BizAccountInfo;
  hasMultiple: boolean;
  onChangeProfileClick: () => void;
}

export const BizProfileConfirmStep = ({
  businessProfile,
  hasMultiple,
  onChangeProfileClick,
}: PropsWithChildren<BizProfileSelectedListProps>) => {
  const { code, target } = useActivityParams<TypeActivityParams<'ActivityConnect'>>();

  const { push, pop } = useFlow();
  const user = useAuthContext();

  const hoianId = _isApp && !!user ? user.id.toString() : (import.meta.env.VITE_HOIAN_ID as string);

  const { refetch } = useRedeemQR({
    code,
    target,
    header: {
      'x-business-account-id': businessProfile.id,
      'x-hoian-id': hoianId,
    },
  });

  return (
    <div className={containerRootStyle}>
      <div className={wrapperStyle}>
        <Avatar
          size="medium"
          src={businessProfile.thumbImageUrl!}
          alt={'선택한 비즈프로필 썸네일'}
          fallback={undefined}
        />
        <div className={profileDetailInfoContainerStyle}>
          <h3 className={typography.subtitle1Bold}>{businessProfile.name}</h3>
          <p className={profileRegionStyle}>{businessProfile.region.name}</p>
        </div>
        {hasMultiple && (
          <BoxButton
            size="xsmall"
            variant="secondary"
            suffix={<IconChevronRightFill width={12} height={12} />}
            onClick={onChangeProfileClick}
          >
            프로필 변경
          </BoxButton>
        )}
      </div>
      {hasMultiple && (
        <div className={disclaimerStyle}>
          <IconWarningFill color={vars.$scale.color.gray600} width={20} height={20} className={warningIconFillStyle} />
          <p className={disclaimerTextStyle}>한 번 연결하면 다른 비즈프로필로 바꿀 수 없어요</p>
        </div>
      )}
      <div className={boxButtonWrapperStyle}>
        <BoxButton
          width={'100%'}
          size="xlarge"
          onClick={async () => {
            const { isError, isSuccess } = await refetch();
            if (isSuccess) {
              push('ActivitySuccess', {
                type: TargetType.BA,
                targetId: businessProfile.id,
              });
              return;
            }
            if (isError) {
              pop(hasMultiple ? 2 : 1, {
                animate: false,
              });

              setTimeout(() => {
                push('ActivityModal', {
                  title: '연결에 실패했어요',
                  message: '다시 한 번 시도해주세요.',
                  buttonActionType: 'pop',
                  buttonText: '확인',
                });
              });
              return;
            }
          }}
        >
          연결하기
        </BoxButton>
      </div>
    </div>
  );
};
