import { Spinner } from '@daangn/sprout-components-spinner';
import React from 'react';

import { SpinnerWrapper } from './FallbackSpinner.css';

interface SpinnerProps {
  size?: 'small' | 'medium';
  variant?: 'primary' | 'gray' | 'white';
}

export const FallbackSpinner = ({ size }: React.PropsWithChildren<SpinnerProps>) => {
  return (
    <div className={SpinnerWrapper}>
      <Spinner size={size} />
    </div>
  );
};
