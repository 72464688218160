import type { StackflowReactPlugin } from '@stackflow/react';

import { AuthContextProvider } from '../context';

const KarrotUserAuthPlugin = (): StackflowReactPlugin => () => {
  return {
    key: 'KarrotUserAuthPlugin',
    wrapStack: ({ stack }) => {
      return <AuthContextProvider>{stack.render()}</AuthContextProvider>;
    },
  };
};

export default KarrotUserAuthPlugin;
