import type { BizAccountInfo } from '@daangn/business-sdk-webview';
import { vars } from '@seed-design/design-token';
import { IconCloseRegular } from '@seed-design/icon';
import { BottomSheet } from '@stackflow/plugin-basic-ui';
import type { ActivityComponentType } from '@stackflow/react';
import React from 'react';

import { FallbackSpinner } from '../../components/FallbackSpinner';
import type { SearchParams } from '../../constants';
import { _isApp } from '../../constants';
import { useFlow } from '../../stackflow';
import { typography } from '../../styles/f.css';
import { useLoadbizAccountList } from '../../utils';
import { containerStyle, headerStyle, messageStyle, spacerStyle, titleRowStyle } from './BizProfileHalfView.css';
import { BizProfileList } from './BizProfileList';
import { BizProfileConfirmStep } from './BizProfileSelectedList';

/**
 * @description 바텀시트 컴포넌트처럼 생겼지만 실제로는 액티비티에요. 히스토리 플러그인에 연동시켜 백버튼 액션을 활용하기 위해 독립적인 액티비티처럼 취급해요. 이전 화면에서 push 로 진입해주세요.
 * @see https://daangn.slack.com/archives/CGDR2PPM2/p1666749798994409?thread_ts=1666345466.719189&cid=CGDR2PPM2
 *
 */
const BizProfileHalfView: ActivityComponentType<SearchParams> = () => {
  const { pop, replace } = useFlow();

  const [selectedBizProfile, setSelectedBizProfile] = React.useState<BizAccountInfo | null>(null);

  const { data: bizAccountList, isError } = useLoadbizAccountList();

  React.useEffect(() => {
    if (isError || bizAccountList === undefined) {
      setTimeout(() => {
        replace(
          'ActivityModal',
          {
            title: '오류가 발생했어요',
            message: '다시 한 번 시도해주세요. 그래도 문제가 지속될 경우 문의하기 버튼을 눌러 알려주세요.',
            buttonActionType: 'close',
            buttonText: '확인',
            hasSupportButton: 'true',
          },
          {
            animate: false,
          }
        );
      }, 0);
      return;
    }
    if (bizAccountList.length === 1) {
      setSelectedBizProfile(bizAccountList[0]);
    }
  }, [bizAccountList]);

  if (bizAccountList === undefined) {
    setTimeout(() => {
      replace(
        'ActivityModal',
        {
          title: '오류가 발생했어요',
          message: '다시 한 번 시도해주세요. 그래도 문제가 지속될 경우 문의하기 버튼을 눌러 알려주세요.',
          buttonActionType: 'close',
          buttonText: '확인',
          hasSupportButton: 'true',
        },
        {
          animate: false,
        }
      );
    }, 0);
    return;
  }

  const popCount = bizAccountList.length > 1 && !!selectedBizProfile ? 2 : 1;
  return (
    <BottomSheet onOutsideClick={pop} dimBackgroundColor={vars.$semantic.color.overlayDim}>
      <React.Suspense fallback={<FallbackSpinner />}>
        {!selectedBizProfile && bizAccountList.length > 1 && (
          <HeaderSection
            title="비즈프로필 선택"
            message="QR 코드에 연결할 비즈프로필을 하나만 선택해주세요."
            popCount={popCount}
          >
            <BizProfileList
              onItemClick={(bizProfile) => setSelectedBizProfile(bizProfile)}
              bizAccounts={bizAccountList}
            />
          </HeaderSection>
        )}
        {selectedBizProfile && (
          <div
            style={{
              maxHeight: '600px',
              overflow: 'scroll',
            }}
          >
            <HeaderSection title="비즈프로필 연결" message="이 비즈프로필을 QR 코드에 연결할까요?" popCount={popCount}>
              <BizProfileConfirmStep
                businessProfile={selectedBizProfile}
                hasMultiple={bizAccountList.length > 1}
                onChangeProfileClick={() => setSelectedBizProfile(null)}
              />
            </HeaderSection>
          </div>
        )}
      </React.Suspense>
    </BottomSheet>
  );
};

interface HeaderSectionProps {
  title: string;
  message: string;
  // 다수의 비즈프로필이 있을 경우, 선택한 이후라면 pop을 두번 해줘야 해요.
  popCount: number;
}

const HeaderSection = ({ message, title, children, popCount }: React.PropsWithChildren<HeaderSectionProps>) => {
  const { pop } = useFlow();

  return (
    <div className={containerStyle}>
      <div className={headerStyle}>
        <div className={titleRowStyle}>
          <h3 className={typography.title2Bold}>{title}</h3>
          <IconCloseRegular
            width={24}
            height={24}
            onClick={() => pop(popCount)}
            role="button"
            aria-label="비즈계정 선택창 닫기"
          />
        </div>
        <div>
          <p className={messageStyle}>{message}</p>
        </div>
      </div>
      <div className={spacerStyle({ type: 'headerAndList' })} />
      {children}
    </div>
  );
};

export default BizProfileHalfView;
