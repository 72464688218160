import { useQuery } from '@tanstack/react-query';
import type { HTTPError, KyResponse } from 'ky';

import type { DefaultError, TargetType } from '../constants';
import { _isApp } from '../constants';
import { kyClient } from '.';

/**
 *
 * @param code 연결할 대상 QR코드
 * @param target 연결할 대상 타입
 * @param header 연결할 대상에 따른 헤더 레코드를 넣어요. `x-business-account-id`, `x-advertiser-id`
 * @returns
 */
interface RedeemQrCodeProps {
  code: string;
  target: TargetType;
  header: Record<string, string>;
}

export const postBizAccountToQRWorker = async ({ code, target, header }: RedeemQrCodeProps) => {
  try {
    const response = await kyClient.post(`${import.meta.env.VITE_API_URL}/qr/${code}/redeem?target=${target}`, {
      headers: header,
    });
    return response;
  } catch (e) {
    const error = e as HTTPError;
    const errorJson = await error.response.json();

    throw errorJson;
  }
};

export const useRedeemQR = ({ code, target, header }: RedeemQrCodeProps) => {
  const { data, isPending, refetch, isError } = useQuery<KyResponse, DefaultError>({
    queryFn: async () => await postBizAccountToQRWorker({ code, target, header }),
    queryKey: ['redeemQrCode', code, target, header],
    enabled: false,
  });

  return { data, isPending, refetch, isError };
};
