import ky from 'ky';
import { createKyHooks } from 'plantae/ky';

import { bridge } from '../bridge';
import { isApp } from '.';
import plantaeAuthPlugin from './plantaeAuthPlugin';

export const makeClient = () => {
  const isAppEnvironment = isApp(bridge.driver.getCurrentEnvironment());
  const client = ky.create({
    headers: {
      'Content-Type': 'application/json',
    },
    hooks: createKyHooks({
      client: ky,
      plugins: [plantaeAuthPlugin(bridge, isAppEnvironment)],
    }),
    retry: 3,
  });

  return client;
};

export const kyClient = makeClient();
