import '@daangn/sprout-components-button/index.css';
import '@daangn/sprout-components-spinner/index.css';
import '@daangn/sprout-components-avatar/index.css';

import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import eruda from 'eruda';
import React, { Suspense } from 'react';

import { BridgeProvider } from './bridge';
import { createStack } from './stackflow';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_STAGE,
  integrations: [
    new Sentry.Replay({
      networkRequestHeaders: ['x-auth-token', 'x-hoian-id', 'x-business-account-id'],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});

if (import.meta.env.VITE_STAGE === 'alpha') {
  eruda.init();
}

const App: React.FC = () => {
  const { Stack } = React.useMemo(() => createStack(history), []);
  return (
    <BridgeProvider>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <Stack />
        </QueryClientProvider>
      </Suspense>
    </BridgeProvider>
  );
};

export default App;
