import type { StackflowReactPlugin } from '@stackflow/react';

import { bridge } from '../bridge';
import { useRouteTitles } from '../utils/useRouteTitles';

/**
 * @description Stack을 감싸고 전역적으로 호출될 플러그인들을 등록해요.
 * @returns
 */
export const globalConfigPlugin = (): StackflowReactPlugin => () => {
  const onInit = () => {
    // 첫 Stack init 할때 브릿지를 이용해 상단바를 제거해요.
    bridge.styleCurrentRouter({
      router: {
        navbar: false,
      },
    });
  };

  return {
    key: 'globalConfigPlugin',
    onInit,
    wrapStack: function GlobalWrapStack({ stack }) {
      const topActivity = stack.activities.find((activity) => activity.isTop)!;
      useRouteTitles({ activity: topActivity });

      return <>{stack.render()}</>;
    },
  };
};
export default globalConfigPlugin;
