import { openMinikarrotWebview } from '@daangn/webview-link-router';
import { IconCloseRegular } from '@seed-design/icon';
import type { ActivityComponentType } from '@stackflow/react';
import lottie from 'lottie-web';
import type { PropsWithChildren } from 'react';
import React from 'react';

import * as checkRoundAnimation from '../../assets/check_round_animation.json';
import { bridge } from '../../bridge';
import ActivityScreen from '../../components/ActivityScreen';
import BottomCenterButton from '../../components/BottomCenterButton';
import { IS_PROD, TargetType } from '../../constants';
import type { TypeActivityParams } from '../../stackflow';
import { getBizPlatformRouter } from '../../utils';
import { lottieDiv, successMessageStyle, titleStyle, wrapperStyle } from './ActivitySuccess.css';

/**
 *
 * @param param0 { type, targetId } type: TargetType; targetId: 해당 타겟의 id. 비즈프로필 id, 광고주 id 등등. 확인 버튼 클릭시 라우트 목적.
 */
const ActivitySuccess: ActivityComponentType<{ type: TargetType; targetId: string }> = ({ params }) => {
  const { type, targetId } = params;
  return (
    <ActivityScreen
      appBar={{
        closeButton: {
          renderIcon: () => <IconCloseRegular width={24} height={24} />,
          onClick() {
            bridge.closeRouter({});
          },
        },
      }}
    >
      <ActivityContent type={type} targetId={targetId} />
    </ActivityScreen>
  );
};
const titleMap = {
  [TargetType.BA]: '성공적으로 연결했어요!',
  [TargetType.ADCP]: '성공적으로 등록했어요!', // SAMPLE
};

const messageMap = {
  [TargetType.BA]: '이제 동네 이웃들이 QR 코드를 찍고\n간편하게 단골을 맺을 수 있어요',
  [TargetType.ADCP]: '', // SAMPLE
};

const buttonLabelMap = {
  [TargetType.BA]: '비즈프로필 홈으로',
  [TargetType.ADCP]: '', // SAMPLE
};

const ActivityContent = ({ type, targetId }: PropsWithChildren<TypeActivityParams<'ActivitySuccess'>>) => {
  const lottieContainer = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const animation = lottie.loadAnimation({
      autoplay: true,
      renderer: 'svg',
      loop: false,
      animationData: JSON.parse(JSON.stringify(checkRoundAnimation)),
      container: lottieContainer.current!,
    });
    setTimeout(() => {
      // lottie 애니메이션 중 체크 스트로크가 그려지는 순간까지 기다려요(약 0.5초)
      bridge.setHapticSuccess({});
    }, 500);

    return () => {
      animation.destroy();
    };
  }, []);

  const handleButtonClick = () => {
    const bizPlatformRouter = getBizPlatformRouter(IS_PROD);
    switch (type) {
      case TargetType.BA:
        openMinikarrotWebview({
          url: `${bizPlatformRouter}/biz_accounts/${targetId}/viewer/home?referrer=bizpop-qr`,
          navbar: false,
          stage: IS_PROD ? 'production' : 'alpha',
        });
        break;
      case TargetType.ADCP:
        //@TBD
        break;
      default:
        // do nothing
        break;
    }
  };

  return (
    <div className={wrapperStyle}>
      <div className={lottieDiv} ref={lottieContainer} />
      <h1 className={titleStyle}>{titleMap[type]}</h1>
      <p className={successMessageStyle}>{messageMap[type]}</p>
      <BottomCenterButton onClick={handleButtonClick}>{buttonLabelMap[type]}</BottomCenterButton>
    </div>
  );
};

export default ActivitySuccess;
