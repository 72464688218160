import type { Activity } from '@stackflow/core';
import * as React from 'react';

import { routes } from '../stackflow';

export function useRouteTitles({ activity }: { activity: Activity }) {
  const pathnameRef = React.useRef<string>();
  const newPathname =
    activity && activity.context && (activity.context as { path: string }).path
      ? (activity.context as { path: string }).path
      : routes().ActivityHome;

  React.useEffect(() => {
    const newTitle = getTitleFromRoutePath(newPathname);

    if (newPathname !== pathnameRef.current) {
      pathnameRef.current = newPathname;
      document.title = newTitle;
    }
  }, [newPathname]);
}

/**
 * routerPath 형태로부터 타이틀을 추출해요.
 * 경로에 id가 들어가는 경우는 별도로 match 함수를 이용해서 판별해요.
 */
const getTitleFromRoutePath = (location: string): string => {
  switch (location) {
    case routes().ActivityHome:
      return '당근비즈 POP - 홈';
    default:
      return '당근비즈니스';
  }
};
