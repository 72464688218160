import React from 'react';

import { useLoadBridgeUser } from '../utils';

type BridgeUserType = ReturnType<typeof useLoadBridgeUser>['data'];

export const AuthContext = React.createContext<BridgeUserType | null | undefined>(null);

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);

  return context;
};

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useLoadBridgeUser();

  return <AuthContext.Provider value={data}>{children}</AuthContext.Provider>;
};
