import { Spinner } from '@daangn/sprout-components-spinner';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import * as React from 'react';

import { disableLongPress } from '../styles/f.css';
import { CircularProgressStyle } from './ActivityScreren.css';

type BaseProps = React.ComponentProps<typeof AppScreen>;

function ActivityBaseScreen({ children, ...props }: BaseProps) {
  return <AppScreen {...props}>{children}</AppScreen>;
}

type Props = BaseProps & {
  fallback?: React.ReactNode;
};

function ActivityScreen({
  children,
  fallback = (
    <Spinner
      UNSAFE_className={CircularProgressStyle({
        isFull: true,
      })}
    />
  ),
  ...props
}: Props) {
  return (
    <div className={disableLongPress}>
      <AppScreen {...props}>
        <React.Suspense fallback={fallback}>{children}</React.Suspense>
      </AppScreen>
    </div>
  );
}

export { ActivityBaseScreen };
export default ActivityScreen;
