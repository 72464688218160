import { BoxButton } from '@daangn/sprout-components-button';
import { vars } from '@seed-design/design-token';
import { Modal } from '@stackflow/plugin-basic-ui';
import type { ActivityComponentType } from '@stackflow/react';
import React from 'react';

import { useBridge } from '../../bridge';
import { IS_PROD } from '../../constants';
import { useFlow } from '../../stackflow';
import { buttonAreaStyle, buttonStyle, containerStyle, contentAreaStyle, titleStyle } from './ActivityModal.css';

interface ModalProps {
  title: string;
  message: string;
  buttonText: string;
  buttonActionType: 'pop' | 'close';
  hasSupportButton?: 'true' | 'false';
}

const ActivityModal: ActivityComponentType<ModalProps> = ({ params }) => {
  const { title, message, buttonActionType, buttonText, hasSupportButton } = params;
  const bridge = useBridge();
  const { pop } = useFlow();

  const onConfirmClick = () => {
    if (buttonActionType === 'close') {
      bridge.closeRouter({});
      return;
    }
    if (buttonActionType === 'pop') {
      pop();
      return;
    }
  };

  const onSupportButtonClick = () => {
    location.href = `karrot${IS_PROD ? '' : '.alpha'}://web/wv/feedbacks/new?category_id=667`;
  };

  return (
    <Modal dimBackgroundColor={vars.$semantic.color.overlayDim}>
      <div className={containerStyle}>
        <div className={contentAreaStyle}>
          <h3 className={titleStyle}>{title}</h3>
          <p>{message}</p>
        </div>
        <div className={buttonAreaStyle}>
          <BoxButton
            size="medium"
            onClick={onConfirmClick}
            variant={hasSupportButton ? 'secondary' : 'primary'}
            width={'100%'}
          >
            {buttonText}
          </BoxButton>
          {Boolean(hasSupportButton) && (
            <BoxButton
              size="medium"
              onClick={onSupportButtonClick}
              variant="primary"
              width="100%"
              UNSAFE_className={buttonStyle}
            >
              문의하기
            </BoxButton>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ActivityModal;
