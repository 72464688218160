import { useQuery } from '@tanstack/react-query';

import { _isApp } from '../constants';
import { useAuthContext } from '../context';
import { businessSDK } from './businessSDK';

export const loadBusinessAccountList = async (authToken: string) => {
  try {
    return await businessSDK.core.profileAPI.reqBizAccountList(authToken, true);
  } catch (error) {
    throw error;
  }
};

export const useLoadbizAccountList = () => {
  const user = useAuthContext();

  const authToken = _isApp ? user?.authToken : import.meta.env.VITE_AUTH_TOKEN;

  const { data, isPending, isError } = useQuery({
    queryFn: async () => await loadBusinessAccountList(authToken),
    queryKey: ['bizAccountList'],
  });

  return { data, isPending, isError };
};
