import { bridge } from './bridge';
import { isApp } from './utils';

export enum TargetType {
  BA = 'BA',
  ADCP = 'ADCP',
}

export interface SearchParams {
  code: string;
  target: TargetType;
  referrer?: string;
}

export const IS_PROD = import.meta.env.VITE_STAGE === 'production';

export const _isApp = isApp(bridge.driver.getCurrentEnvironment());
export interface DefaultError {
  err: string;
  info?: Record<string, string>;
}
