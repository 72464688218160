import type { makeKarrotBridge } from '@daangn/karrotbridge';
import { useQuery } from '@tanstack/react-query';

import { useBridge } from '../bridge';

const loadBridgeUser = async (bridge: ReturnType<typeof makeKarrotBridge>) => {
  return (await bridge.getUserInfo({})).info.user;
};

export const useLoadBridgeUser = () => {
  const bridge = useBridge();

  const { data, isLoading } = useQuery({
    queryFn: async () => await loadBridgeUser(bridge),
    queryKey: ['bridgeUser'],
    // enabled: isApp(bridge.driver.getCurrentEnvironment()),
  });

  return { data, isLoading };
};
