import * as React from 'react';

import BizProfileHalfView from '../activities/ActivityBizProfileHalfView/BizProfileHalfView';
import ActivityModal from '../activities/ActivityModal/ActivityModal';
import ActivitySuccess from '../activities/ActivitySuccess/ActivitySuccess';

const activities = {
  ActivityHome: React.lazy(() => import('../activities/ActivityHome/ActivityHome')),
  ActivityConnect: React.lazy(() => import('../activities/ActivityConnect/ActivityConnect')),
  ActivityFollow: React.lazy(() => import('../activities/ActivityFollow/ActivityFollow')),
  ActivitySuccess,
  ActivityBizProfileHalfView: BizProfileHalfView,
  ActivityModal,
};

const routes = () => ({
  // 연결 화면을 메인으로 사용해요.
  ActivityHome: '/',
  ActivityConnect: '/connect',
  ActivityFollow: '/follow',
  ActivityBizProfileHalfView: '/biz-profile-half-view',
  ActivityModal: '/modal',
  ActivitySuccess: '/success',
});

export { activities, routes };
