import type { BizAccountInfo } from '@daangn/business-sdk-webview';
import { Avatar } from '@daangn/sprout-components-avatar';
import React from 'react';

import { _isApp } from '../../constants';
import { typography } from '../../styles/f.css';
import { bizInfoRowStyle, bizProfileListItemStyle, listWrapperStyle, regionStyle } from './BizPrlfileList.css';

interface BizProfileListProps {
  bizAccounts: BizAccountInfo[];
  onItemClick: (targetProfile: BizAccountInfo) => void;
}

export const BizProfileList = ({ onItemClick, bizAccounts }: BizProfileListProps) => {
  return (
    <ul className={listWrapperStyle}>
      {bizAccounts?.map((bizAccount) => {
        return (
          <BizProfileListItem
            key={bizAccount.id}
            avatar={bizAccount.thumbImageUrl!}
            title={bizAccount.name}
            region={bizAccount.region.name}
            onClick={() => {
              onItemClick(bizAccount);
            }}
          />
        );
      })}
    </ul>
  );
};

interface BizProfileListItemProps {
  avatar: string;
  title: string;
  region: string;
  onClick: () => void;
}

const BizProfileListItem = ({
  children,
  title,
  region,
  onClick,
  avatar,
}: React.PropsWithChildren<BizProfileListItemProps>) => {
  return (
    <li className={bizProfileListItemStyle} onClick={onClick}>
      <Avatar size="medium" src={avatar} alt={`${title} 비즈프로필 아바타`} fallback={undefined} />
      <div className={bizInfoRowStyle}>
        <h3 className={typography.subtitle1Bold}>{title}</h3>
        <p className={regionStyle}>{region}</p>
      </div>
    </li>
  );
};
