import { BoxButton } from '@daangn/sprout-components-button';
import { useStack } from '@stackflow/react';
import clsx from 'clsx';
import { rem } from 'polished';
import * as React from 'react';
import ReactDOM from 'react-dom';

import { safeAreaBottom } from '../styles/f.css';
import { buttonWrapperStyle } from './BottomCenterButton.css';

type TBottomCenterButtonProps = React.ComponentProps<typeof BoxButton> & {
  description?: React.ReactNode;
  form?: string;
};

function BottomCenterButton({ description, ...props }: TBottomCenterButtonProps) {
  const [spaceHeight, setSpaceHeight] = React.useState(0);
  const stack = useStack();
  const currentActivity = stack.activities.find((activity) => activity.isTop);
  const activityRef = React.useRef(currentActivity);

  return (
    <>
      <div style={{ height: rem(spaceHeight) }} />
      {ReactDOM.createPortal(
        <div
          ref={(node) => {
            setSpaceHeight(node?.firstElementChild?.clientHeight ?? 0);
          }}
        >
          <div
            className={clsx(
              buttonWrapperStyle({
                isActivityTop:
                  currentActivity?.transitionState.startsWith('enter') &&
                  activityRef.current?.id === currentActivity?.id,
              }),
              safeAreaBottom
            )}
          >
            <BoxButton {...props} size="xlarge" width="100%" elementType="div" />
          </div>
        </div>,
        document.getElementById('portal')!
      )}
    </>
  );
}

export default BottomCenterButton;
